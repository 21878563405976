import { Routes, Route } from 'react-router-dom';
import { MiSuite } from './components/MiSuite';
import { Footer } from './components/Footer';
import { Nav } from './components/Nav';
import { SkipNav } from './components/SkipNav';
import { NotFound } from './components/NotFound';
import { Home } from './components/Home';
import { Forecasting } from './components/Forecasting';
import { Winemakers } from './components/Winemakers';
import { DesignSystem } from './components/DesignSystem';
import { ComponentLibrary } from './components/ComponentLibrary';

function App() {
  return (
    <>
      <SkipNav />
      <Nav />
      <main role="main" id="content">
        <div className="container pb-5">
          <Routes>
            <Route path ="/" element={<Home />} />
            <Route path='/digitizing-government' element={<MiSuite/>}/>
            <Route path='/forecasting-efficiency' element={<Forecasting/>}/>
            <Route path='/grape-to-glass' element={<Winemakers/>}/>
            <Route path='/building-reusable-ui' element={<DesignSystem/>}/>
            <Route path='/component-library' element={<ComponentLibrary/>}/>
            <Route element={<NotFound/>}/>
          </Routes>
          <div className="row">
            <div className="col">
              &nbsp;
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default App;