const Winemakers = () => {
  return (
    <>
      <div className="row">
        <div className="col">
          &nbsp;
        </div>
      </div>
      <div className="row">
        <div className="col-xs-1">
            <h2 className="h5 text-danger">PROBLEM <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
            <p className="h5">
              The craft beverage platform did not extend to wineries.
            </p>
          <p>
            The intricacies of winemaking, including different production cycles, terminology, and data handling, posing significant obstacles. 
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row">
          <div className="col-xs-1">
          <h2 className="h5 text-danger">PROCESS <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
            <p>

            </p>
          </div>
      </div>
      <div className="row">
          <div className="col">
          <img src="../wine-affinity-map.png" className="main-image rounded mx-auto d-block" alt="..."></img>
          </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-xs-1">
            <h2 className="h5 text-danger">SOLUTION <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
            <p className="h5">
              Seamlessly adapt our existing brewery-focused platform to meet the distinct needs of wineries, ensuring that it could efficiently manage every aspect of wine production, from grape to glass.
            </p>
        </div>
        </div>
    </>
  );
};

export { Winemakers };