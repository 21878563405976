const ComponentLibrary = () => {
  return (
    <>
      <div className="row">
        <div className="col">
        <a
          href="https://kyber-js.reason.consulting/about?theme=reason" target="_blank" rel="noreferrer">
          Live Preview!
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-1">
          <h2 className="h5 text-danger">PROBLEM <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
          <p className="h5">
            While the design system significantly improved design consistency, the need for faster and more 
            efficient design and development processes became apparent.
          </p>
          <p>
            Designers still encountered challenges in implementing design elements, and developers faced 
            obstacles in translating designs into functional components. Both teams needed a solution to streamline 
            the process and further reduce inconsistencies.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-1">
          <h2 className="h5 text-danger">PROCESS <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
          <p className="h5">
            To address the need for a more efficient design and development process, we embarked on the following steps:
          </p>
          <ol>
            <li>
              <p>
                Conducted collaborative workshops involving designers and developers to understand their specific 
                pain points and challenges.
              </p>
            </li>
            <li>
              <p>
                Utilized the existing design system as the foundation and extended it to create a component library.
              </p>
            </li>
            <li>
              <p>
                Developed and documented a comprehensive set of reusable UI components that adhered to the design 
                guidelines.
              </p>
            </li>
            <li>
              <p>
                Provided clear usage guidelines, documentation, and code examples for each component to assist 
                designers and developers.
              </p>
            </li>
            <li>
              <p>
                Conducted training sessions and workshops to ensure that both design and development teams were 
                proficient in using the component library.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-xs-1">
          <h2 className="h5 text-danger">SOLUTION <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
          <p className="h5">
            The introduction of a component library further improved the design and development process, leading 
            to faster and more consistent results.
          </p>
          <p>
            Designers could now easily access and utilize a comprehensive library of pre-designed components, 
            significantly reducing design iteration time and ensuring better design consistency.
          </p>
          <p>
            Developers could seamlessly integrate these components into their projects, reducing development 
            time, and ensuring a more consistent and accurate implementation of designs.
          </p>
          <p>
            The collaborative nature of the component library facilitated better communication and understanding 
            between design and development teams.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          &nbsp;
        </div>
      </div>
    </>
  );
};

export {ComponentLibrary};
