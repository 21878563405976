const Nav = () => {
  return (
    <nav className="navbar navbar-expand-md">
      <div className="container">
          <a className="navbar-brand custom-primary" href="/"><strong>{"ANNE ERVIN"}</strong>&nbsp;Product Designer</a>
      </div>
    </nav>
  );
};

export { Nav };
