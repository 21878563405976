import React from "react";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className="footer">
                <div className="custom-primary">Made with <i className="fa-solid fa-wand-magic-sparkles"></i>{' '}&copy; Anne Ervin {currentYear}</div>
            </div>
        </footer>
    );
};

export {Footer};