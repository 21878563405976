const NotFound = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1>Page Not Found</h1>
        </div>
        </div>
      </div>
  );
};

export {NotFound};

