const Forecasting = () => {
  return (
    <>
      <div className="row">
        <div className="col">
          &nbsp;
        </div>
      </div>
      <div className="row">
        <div className="col-xs-1">
            <h2 className="h5 text-danger">PROBLEM <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
            <p className="h5">
              The State of Michigan's Equalization Department maintained an entirely offline process.
            </p>
          <p>
          The users were burdened with the need for extensive manual data entry, 
          resulting in frequent errors. Limited data accessibility added to inefficiencies
          and compromised data security within the offline equalization process.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row">
          <div className="col-xs-1">
          <h2 className="h5 text-danger">PROCESS <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
            <p>
              We conducted in-depth interviews with Equalization Department users, 
              observed them in action, and collected feedback through surveys. This 
              allowed us to understand their workflows, pain points, and challenges
              tied to the offline system. We also assessed the use of spreadsheets
              to comprehensively grasp the complexities of the existing process.
            </p>
            <p>
              Next, we reviewed data access permissions to identify limitations. This audit
              clarified user data access and restrictions. Additionally, we assessed 
              existing calculation algorithms to grasp technical complexities and the 
              challenges related to data maintenance.
            </p>
            <p>
              Several critical challenges were identified. The manual data entry process led to errors, while 
              limited data accessibility hindered efficient data sharing. Inefficiencies plagued the workflow, 
              and concerns arose regarding data security and integrity. The complexity of calculation algorithms 
              and an overreliance on a single data source maintained by one individual posed significant obstacles.
              Additionally, not all users had access to essential data, and the complexity of tax forms scattered 
              critical information. Breaking the habit of relying on spreadsheets and adhering to a tight project 
              launch timeline further compounded these challenges.
            </p>
          </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-xs-1">
            <h2 className="h5 text-danger">SOLUTION <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
            <p className="h5">
              A suite of applications that includes user management with authentication, authorization, roles, and permissions.
            </p>
          <p>
            The result of this project culminated in the development of an online suite of applications, each 
            tailored to the specific role of the user within the equalization process, aligning seamlessly with 
            their job titles. These purpose-built applications streamlined the data input process and facilitated 
            the accurate flow of data throughout the hierarchy. 
          </p>
          <p>
            Crucially, the suite of applications harmonized the disparate data entry methods used across 
            all counties into a single unified source. This central repository featured embedded calculations, 
            ensuring data accuracy and consistency. Furthermore, the suite provided state-of-the-art analytics 
            capabilities, equipping the state with valuable insights into its equalization process. 
          </p>
        </div>
        </div>
    </>
  );
};

export { Forecasting };