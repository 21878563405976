const DesignSystem = () => {
  return (
    <>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
      <div className="row">
        <div className="col-xs-1">
          <h2 className="h5 text-danger">PROBLEM <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
        </div>
      </div>
        <div className="row">
          <div className="col">
          <p className="h5">
            Users, designers, and developers across the organization were struggling with design inconsistency, 
            leading to a fragmented user experience.
          </p>
         </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
        <div className="row">
                <div className="col-sm-4">
                  <figure className="figure">
                    <img src="../color.png" className="figure-img img-fluid rounded" alt="No consistent usage of color." />
                    <figcaption className="figure-caption">Over 70 colors used through out the application.</figcaption>
                  </figure>
                </div>
                <div className="col-sm-4">
                  <figure className="figure">
                    <img src="../typography.png" className="figure-img img-fluid rounded " alt="Various different typography used through out the application." />
                    <figcaption className="figure-caption">Various different typography used through out the application.</figcaption>
                  </figure>
                </div>
                <div className="col-sm-4">
                  <figure className="figure">
                    <img src="../buttons.png" className="figure-img img-fluid rounded" alt="Various button states and styles." />
                    <figcaption className="figure-caption">Various button states and styles.</figcaption>
                  </figure>
                </div>
              </div>
        <div className="row">
          <div className="col">
          <p>
            End-users found it confusing and frustrating to interact with products that featured inconsistent 
            UI elements, varying colors, and typography. This inconsistency hindered the intuitive use of our 
            applications and led to reduced user satisfaction.
          </p>
          <p>
            Designers encountered challenges in maintaining a cohesive and unified design language. The lack 
            of standardized design components and styles slowed down the design process, leading to longer 
            project timelines.
          </p>
          <p>
            Developers faced difficulties in implementing designs due to the absence of a consistent design 
            system. This resulted in longer development cycles, increased chances of introducing bugs, and 
            hindered collaboration between design and development teams.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2 className="h5 text-danger">PROCESS <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="h5">
            To tackle the issue of inconsistency, we embarked on a structured process that included the following steps:
          </p>
          <ol>
            <li>
              <p>
                Conducted a comprehensive audit of our existing design assets, identifying areas of inconsistency 
                and disparity in design elements.
              </p>
            </li>
            <li>
              <p>
                Collaborated with designers, developers, and stakeholders to define a set of design principles 
                and guidelines that would serve as the foundation for our design system.
              </p>
            </li>
            <li>
              <p>
                Created a central design repository where all approved design components, assets, and guidelines 
                were documented and made accessible to the entire team.
              </p>
            </li>
            <li>
              <p>
                Trained designers and developers on the use of the design system and provided resources for 
                implementation.
              </p>
            </li>
            <li>
              <p>
                Continuously updated and maintained the design system to ensure it remained aligned with evolving 
                design and development needs.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr/>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-1">
          <h2 className="h5 text-danger">SOLUTION <i className="fa-solid fa-angles-right fa-2xs"></i></h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="h5">
            Our solution involved creating a design system to address the issues of inconsistency.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <br/>
        </div>
      </div>
      <div className="row">
                <div className="col-sm-4">
                  <figure className="figure">
                    <img src="../updated-color.png" className="figure-img img-fluid rounded" alt="No consistent usage of color." />
                    <figcaption className="figure-caption text-center">Synthesized color scheme.</figcaption>
                  </figure>
                </div>
                <div className="col-sm-4">
                  <figure className="figure">
                    <img src="../updated-typography.png" className="figure-img img-fluid rounded" alt="Various button states and styles." />
                    <figcaption className="figure-caption text-center">Scalable type.</figcaption>
                  </figure>
                </div>
                <div className="col-sm-4">
                  <figure className="figure">
                    <img src="../updated-buttons.png" className="figure-img img-fluid rounded " alt="Various different typography used through out the application." />
                    <figcaption className="figure-caption text-center">Simplified buttons.</figcaption>
                  </figure>
                </div>
              </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
      <div className="row">
        <div className="col">
          <p>
            Our design system included standardized design components, color palettes, typography, and UI 
            guidelines. This system served as a single source of truth for all design-related aspects.
          </p>
          <p>
            Designers adopted the design system, significantly improving their workflow by providing ready-to-use 
            components and design guidelines. This allowed for faster design iterations and improved design 
            consistency.
          </p>
          <p>
            Developers integrated the design system into the development process, ensuring that the designed 
            elements were accurately and consistently implemented. This resulted in shorter development cycles and 
            smoother collaboration with designers.
          </p>
          <p>
            End-users benefited from a more intuitive and consistent user experience in our products, leading to 
            higher user satisfaction and engagement.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          &nbsp;
        </div>
      </div>

    </>
  );
};

export {DesignSystem};
