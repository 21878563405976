const Home = () => {
    return (
      <>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <img src="../digitizing-government.png" className="rounded widget-image" alt="..."></img>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <h2 className="h5 text-danger text-uppercase">
                      Equalization Evolution
                      </h2>
                      <p>
                      Transition the State of Michigan's Equalization process from offline data 
                      management to an online system, replacing 
                      spreadsheets and email for data sharing and collaboration.
                      </p>
                      <ul>
                        <li>Launched with 100% adoption across 83 counties and 1,773 local units in the first year.</li>
                      </ul>
                      <br/>
                      <a href="/digitizing-government" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <img src="../design-system.png" className="rounded widget-image" alt="..."></img>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <h2 className="h5 text-danger text-uppercase">
                      Unifying Experiences
                    </h2>
                      <p>
                        Creating a design system to streamline the creation of user interfaces by providing standardized components, enhancing design consistency and usability.
                      </p>
                      <ul>
                        <li>30% increase in design & development efficiency</li>
                        <li>20% reduction in user-reported issues related to UI inconsistencies</li>
                        <li>Scalability and improved communication between product, design and engineering teams</li>
                      </ul>
                      <br/>
                      <a href="/building-reusable-ui" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
        {/* <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <img src="../design-system.png" className="rounded widget-image" alt="..."></img>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <h2 className="h5 text-danger text-uppercase">
                      Streamlined Components
                    </h2>
                    <p>
                      Simplifying design and development with a comprehensive component library.
                    </p>
                    <ul>
                      <li>40% reduction in design-to-development handoff time</li>
                      <li>25% decrease in UI-related bugs reported by users</li>
                      <li>Enhanced collaboration and consistency between design and development teams</li>
                    </ul>
                    <a href="/component-library" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div> */}
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <img src="../winemaker.png" className="rounded widget-image" alt="..."></img>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <h2 className="h5 text-danger text-uppercase">
                      From Grape to Glass
                    </h2>
                    <p>
                      Integrating Winemaking to Craft Beverage ERP
                    </p>
                    <br/>
                    <a href="/grape-to-glass" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>

        {/* <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
              <div className="d-flex align-items-center">
                <img src="../forecasting-brewery-sales.png" className="rounded widget-image" alt="..."></img>
                <div className="d-flex flex-column pl-3">
                <h2 className="h5 text-danger text-uppercase">
                    Forecasting Efficiency
                  </h2>
                  <p>
                  Optimizing brewery efficiency from production to sales.
                  </p>
                  <a href="/forecasting-efficiency" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
              <div className="d-flex align-items-center">
                <img src="../winemaker.png" className="rounded widget-image" alt="..."></img>
                <div className="d-flex flex-column pl-3">
                <h2 className="h5 text-danger text-uppercase">
                    Grape to Glass
                  </h2>
                  <p>
                    Integrating Winemaking to Craft Beverage ERP
                  </p>
                  <a href="/grape-to-glass" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col">
            &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col">            
            <div className="card">
              <div className="card-body">
              <div className="d-flex align-items-center">
                <img src="../design-system.png" className="rounded widget-image" alt="..."></img>
                <div className="d-flex flex-column pl-3">
                  <h2 className="h5 text-danger text-uppercase">
                    Unifying User Experiences
                  </h2>
                  <p>
                    Explore design systems and reusable UI components.
                  </p>
                  <a href="/building-reusable-ui" className="card-link text-danger mt-5">View Project&nbsp;<i className="fa-solid fa-arrow-right-long"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
};

export {Home};